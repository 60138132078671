import React from 'react';

import {
    grid,
    titleText,
    subtitleText,
    contentText,
    input,
    list,
    tag,
} from './post-header.module.scss';
import { ISection } from '../../models/section.model';
import { IPostCategory } from '../../models/post-category.model';
import useTranslations from '../../hooks/use-translations';

import Section from '../hoc/section';
import SearchInput, { ISearchInputProps } from '../molecules/search-input';
import CategoryChipList from '../molecules/category-chip-list';

export type TPostHeaderContext = 'category' | 'tag';

export interface IPostHeader extends ISection {
    items?: {
        categories: IPostCategory[];
    };
    settings?: {
        context?: TPostHeaderContext;
        onSearch?: ISearchInputProps['onSearch'];
        initialSearchValue?: ISearchInputProps['initialValue'];
    };
}

export interface IPostHeaderProps {
    className?: string;
    section: IPostHeader;
    TitleTag?: React.ElementType;
}

const PostHeader: React.FC<IPostHeaderProps> = ({ className = '', section, TitleTag = 'h2' }) => {
    const t = useTranslations('PostHeader');
    const {
        sectionId,
        content: { texts },
        css,
        style,
        items,
        settings,
    } = section;
    const { categories } = items || {};
    const { initialSearchValue, onSearch, context = 'category' } = settings || {};

    const contextClass = contextClasses[context];

    return (
        <Section
            sectionId={sectionId}
            className={`${className} ${contextClass}`}
            classes={{
                container: grid,
                title: titleText,
                subtitle: subtitleText,
                description: contentText,
            }}
            title={texts[0]}
            subtitle={texts[1]}
            description={texts[2]}
            TitleTag={TitleTag}
            css={css}
            style={style}
        >
            <SearchInput
                className={input}
                onSearch={onSearch}
                placeholder={t.searchPlaceholder}
                initialValue={initialSearchValue}
            />
            {categories && categories.length > 0 && (
                <CategoryChipList className={list} categories={categories} />
            )}
        </Section>
    );
};

const contextClasses: Record<TPostHeaderContext, string> = {
    category: '',
    tag: tag,
};

export default PostHeader;
